import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useAuth } from "../../../../../context_providers/auth/authProvider";
import {
  defaultEmpValue,
  UserModel,
} from "../../../../../context_providers/auth/user_model";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import {
  CreateEmpRepo,
  GetReportingManager,
} from "../../../../../service/repos/users_repo";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import {
  Departments,
  EmpType,
  SubDepartments,
} from "../../../../../utils/enums";
import HrmButton from "../../components/HrmButton";
import HrmInput from "../../components/Input";
import HrmLoader from "../../components/loading";
import HrmSelect from "../../components/Select";
import Layout from "../../layout";

interface CreateEmployeeProps {
  userData: UserModel;
}

const CreateEmployee: FunctionComponent<CreateEmployeeProps> = ({
  userData,
}) => {
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<UserModel>(defaultEmpValue);
  const { showToast } = useToast();
  const [lineManager, setLineManager] = useState<
    {
      id: any;
      name: string;
      dep: string;
      sub_dep: string;
    }[]
  >([]);

  const getLineManager = async () => {
    const res = await GetReportingManager();
    console.log(res);

    setLineManager(res.data ?? []);
  };

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    if (name.includes(".")) {
      const fields = name.split(".");

      if (fields.length === 3) {
        setFormData((prev: any) => {
          const tempFormData = prev;
          tempFormData[fields[0]][fields[1]][fields[2]] = value;
          return { ...tempFormData };
        });
      } else if (fields.length === 2) {
        setFormData((prev: any) => {
          const tempFormData = prev;
          tempFormData[fields[0]][fields[1]] = value;
          return { ...tempFormData };
        });
      }
    } else setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    e.preventDefault();
    const finalData: UserModel = {
      ...formData,
    };

    if (finalData.emp_profile?.emp_type === EmpType.Intern) {
      finalData.emp_profile.date_of_earned_leave_start = null;
    }

    // console.log(finalData);
    const res = await CreateEmpRepo(finalData);

    if (res.success) {
      showToast({ type: ToastType.success, text: res.message ?? "" });
    } else showToast({ type: ToastType.error, text: res.error ?? "" });
    setLoading(false);
  };

  useEffect(() => {
    getLineManager();
  }, []);

  return (
    <Layout pageTitle="Add new employee">
      <div className="relative">
        <div className="py-12 px-6 lg:px-12">
          <form action="" onSubmit={submit} className="mt-8">
            <div className="">
              <input
                type="radio"
                id="active"
                name="status"
                value="true"
                checked={formData.emp_profile?.active}
                onChange={(e) => {
                  const val = Boolean(e.target.value);
                  setFormData((o) => ({
                    ...o,
                    emp_profile: {
                      ...o.emp_profile!,
                      active: val,
                    },
                  }));
                }}
              />
              <label htmlFor="active" className="font-semibold text-sm ml-2">
                Active
              </label>
              <input
                type="radio"
                id="status"
                name="inactive"
                value="false"
                checked={!formData.emp_profile?.active}
                onChange={(e) => {
                  const val = !Boolean(e.target.value);
                  setFormData((o) => ({
                    ...o,
                    emp_profile: {
                      ...o.emp_profile!,
                      active: val,
                    },
                  }));
                }}
                className="ml-4"
              />{" "}
              <label htmlFor="inactive" className="font-semibold text-sm ml-2">
                Inactive
              </label>
            </div>
            <div
              className={`${
                disabled ? "grid  lg:grid-cols-3" : "grid  lg:grid-cols-3"
              } gap-8 lg:gap-12 mt-4`}
            >
              <HrmInput
                type="text"
                name="first_name"
                label="First name"
                defaultValue={formData?.first_name}
                disabled={disabled}
                required={true}
                onChange={handleChange}
              />
              <HrmInput
                type="text"
                name="last_name"
                label="Last name"
                defaultValue={formData?.last_name}
                disabled={disabled}
                required={true}
                onChange={handleChange}
              />
              <HrmInput
                type="text"
                name="mobile"
                label="Mobile"
                defaultValue={formData?.mobile}
                disabled={disabled}
                required={true}
                onChange={handleChange}
              />
              <HrmInput
                type="email"
                name="email"
                label="Email"
                defaultValue={formData?.email}
                disabled={disabled}
                required={true}
                onChange={handleChange}
              />
              <HrmInput
                type="text"
                name="emp_profile.designation"
                label="Designation"
                defaultValue={formData?.emp_profile?.designation}
                disabled={disabled}
                required={true}
                onChange={handleChange}
              />

              <HrmSelect
                name="emp_profile.department"
                label="Department"
                defaultValue={formData.emp_profile?.department}
                disabled={disabled}
                required={true}
                options={Object.values(Departments)}
                onChange={handleChange}
              />

              <HrmSelect
                name="emp_profile.sub_dep"
                label="Sub department"
                defaultValue={formData.emp_profile?.sub_dep}
                disabled={disabled}
                required={true}
                options={
                  formData.emp_profile?.department
                    ? SubDepartments[formData.emp_profile?.department]
                    : []
                }
                onChange={handleChange}
              />
              {/* Line manager */}
              <div className="">
                <div className="">
                  <div className={`text-sm text-gray-400 font-semibold  `}>
                    Line manager
                  </div>
                  <select
                    name="line_manager"
                    disabled={disabled}
                    className={` w-full ${
                      disabled
                        ? "bg-transparent"
                        : "py-3 px-4 bg-white shadow-md"
                    }  focus:outline-none rounded-lg text-black font-semibold mt-1 cursor-pointer`}
                    onChange={(e) => {
                      const data = lineManager.find(
                        (d) => d.id === e.target.value
                      );

                      setFormData((o) => ({
                        ...o,
                        emp_profile: {
                          ...o.emp_profile!,
                          reporting_to_id: data?.id,
                          reporting_to_name: data?.name,
                        },
                      }));
                    }}
                  >
                    <option
                      value={formData?.emp_profile?.reporting_to_name}
                      hidden
                    >
                      {formData?.emp_profile?.reporting_to_name}
                    </option>
                    {lineManager.map((d) => (
                      <option value={d.id} className="text-black">
                        {d.name}
                      </option>
                    ))}
                  </select>
                </div>{" "}
                {!disabled && (
                  <div className="mt-2 flex items-center">
                    <label htmlFor="reporting">Is also a line manager?</label>

                    <input
                      type="checkbox"
                      className="ml-3"
                      id="reporting"
                      name="line_manager"
                      value=""
                      disabled={disabled}
                      checked={formData.emp_profile?.isReportingManager}
                      onChange={(e) =>
                        setFormData((o) => ({
                          ...o,
                          emp_profile: {
                            ...o.emp_profile!,
                            isReportingManager: e.target.checked,
                          },
                        }))
                      }
                    />
                  </div>
                )}
              </div>

              <HrmSelect
                name="emp_profile.zone"
                label="Zone"
                defaultValue={formData.emp_profile?.zone}
                disabled={disabled}
                required={true}
                options={[
                  "North zone",
                  "South zone",
                  "West zone",
                  "East zone",
                  "Central zone",
                  "All",
                ]}
                onChange={handleChange}
              />

              <div className="">
                <div className={`text-sm text-gray-400 font-semibold  `}>
                  Date of joining
                </div>
                <input
                  type="date"
                  className="shadow-md py-3 px-4 bg-white w-full  focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg text-black font-semibold mt-1"
                  value={
                    formData?.emp_profile?.doj
                      ? moment(formData.emp_profile?.doj).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const date = UTCToLocalDate(e.target.value)!;
                    date.setHours(0, 0, 0, 0);
                    setFormData((o) => ({
                      ...o,
                      emp_profile: {
                        ...o.emp_profile!,
                        doj: date,
                      },
                    }));
                  }}
                />
              </div>

              <div className="">
                <div className={`text-sm text-gray-400 font-semibold  `}>
                  Date of birth
                </div>
                <input
                  type="date"
                  className="shadow-md py-3 px-4 bg-white w-full  focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg text-black font-semibold mt-1"
                  value={
                    formData.dob
                      ? moment(formData.dob).format("YYYY-MM-DD")
                      : ""
                  }
                  onChange={(e) => {
                    const date = UTCToLocalDate(e.target.value)!;
                    date.setHours(0, 0, 0, 0);
                    setFormData((o) => ({
                      ...o,
                      dob: date,
                    }));
                  }}
                />
              </div>

              <HrmInput
                type="text"
                name="emp_profile.emp_id"
                label="Employee ID"
                disabled={disabled}
                required={true}
                onChange={handleChange}
              />

              <HrmSelect
                name="emp_profile.emp_type"
                label="Employee type"
                defaultValue={formData.emp_profile?.emp_type}
                disabled={disabled}
                required={true}
                options={Object.values(EmpType)}
                onChange={handleChange}
              />
              {formData.emp_profile?.emp_type === EmpType.Fulltime && (
                <div className="">
                  <div className={`text-sm text-gray-400 font-semibold  `}>
                    Date of Earned leave start
                  </div>
                  <input
                    type="date"
                    className="shadow-md py-3 px-4 bg-white w-full  focus:outline-none focus:ring-2 focus:ring-blue-500 rounded-lg text-black font-semibold mt-1"
                    value={
                      formData?.emp_profile?.date_of_earned_leave_start
                        ? moment(
                            formData.emp_profile?.date_of_earned_leave_start
                          ).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) => {
                      const date = UTCToLocalDate(e.target.value)!;
                      date.setHours(0, 0, 0, 0);
                      setFormData((o) => ({
                        ...o,
                        emp_profile: {
                          ...o.emp_profile!,
                          date_of_earned_leave_start: date,
                        },
                      }));
                    }}
                  />
                </div>
              )}

              <HrmInput
                type="text"
                name="emp_profile.emergency_contact"
                label="Emergency contact"
                defaultValue={formData.emp_profile?.emergency_contact}
                disabled={disabled}
                required={false}
                onChange={handleChange}
              />
              <HrmInput
                type="text"
                name="emp_profile.blood_group"
                label="Blood group"
                defaultValue={formData.emp_profile?.blood_group}
                disabled={disabled}
                required={false}
                onChange={handleChange}
              />
              <HrmInput
                type="text"
                name="emp_profile.permanent_address"
                label="Permanent address"
                defaultValue={formData.emp_profile?.permanent_address}
                disabled={disabled}
                required={false}
                onChange={handleChange}
              />

              <HrmSelect
                name="emp_profile.education"
                label="Education"
                defaultValue={formData.emp_profile?.education}
                disabled={disabled}
                required={false}
                options={["Under graduate", "Post graduate"]}
                onChange={handleChange}
              />
              <HrmInput
                type="text"
                name="emp_profile.education_degree_name"
                label="Education degree name"
                defaultValue={formData?.emp_profile?.education_degree_name}
                disabled={disabled}
                required={false}
                onChange={handleChange}
              />
              <HrmInput
                type="text"
                name="emp_profile.linked_in"
                label="Linkedin URL"
                defaultValue={formData?.emp_profile?.linked_in}
                disabled={disabled}
                required={false}
                onChange={handleChange}
              />
            </div>
            <div className="flex flex-col items-center mt-12">
              {loading ? (
                <HrmLoader className="w-12 h-12" />
              ) : (
                <HrmButton text="Add employee" type="primary" size="large" />
              )}
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default CreateEmployee;
